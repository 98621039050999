<script>
/**
 * Widget Component
 */
export default {
  props: ["stat"],
  data() {
    return {};
  },
  computed: {
    statData() {
      return this.stat
        ? [
            {
              title: "vehicles with running ad",
              icon: "ri-truck-line",
              value: this.stat.vehiclesWithRunningAd,
            },
            {
              title: "Approved transactions",
              icon: "ri-line-chart-fill",
              value: this.stat.approvedTransactions,
            },
            {
              title: "Unassigned vehicles",
              icon: "ri-car-line",
              value: this.stat.unAssignedVehicles,
            },
          ]
        : [];
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>