<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { analytics } from "@/config/api/analytics";
import Stat from "./widget";
// import mapView from "@/components/maps/mapView";

// import RevenueAnalytics from "./revenue";
// import SalesAnalytics from "./sales-analytics";
// import EarningReport from "./earning";
// import Sources from "./sources";
// import RecentActivity from "./recent-activity";
// import RevenueLocation from "./revenue-location";
// import Chat from "./chat";
// import Transaction from './transaction';

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    // mapView,
    // RevenueAnalytics,
    // SalesAnalytics,
    // EarningReport,
    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // Chat,
    // Transaction
  },
  data() {
    return {
      heatLocations: [],
      heatMapPoints: [],
      mapOptions: {
        radius: 75,
      },
      title: "Dashboard",
      data: null,
      defaultLocation: {
        lat: 24.7136,
        lng: 46.6753,
        zoom: 10,
      },
      loading: false,
      items: [
        {
          text: "BSEEN",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      this.$forceUpdate();

      this.loadAnalytics();
      this.loadHeatMapLocations();
    });
  },
  computed: {
    keyValue: function () {
      return Math.random();
    },
  },
  deferredReady() {
    this.$nextTick(() => {
      this.$refs.gmap.$mapCreated.then((map) => {
        console.log(map);
        // something.setMap(map);
      });
    });
  },
  methods: {
    loadAnalytics() {
      const api = analytics.get;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.data = {
            vehiclesWithRunningAd: res.data.vehiclesWithRunningAd.length,
            inspections: res.data.inspections.length,
            approvedTransactions: res.data.approvedTransactions.length,
            unAssignedVehicles: res.data.unAssignedVehicles.length,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewClusterMap() {
      this.$nextTick(() => {
        this.$router.push({ name: "ClusterMaps" });
      });
    },
    loadHeatMapLocations() {
      const api = analytics.heatmap;
      this.generateAPI(api)
        .then((res) => {
          if (res.data.dashboardHeatLocations) {
            for (const key in res.data.dashboardHeatLocations) {
              if (
                Object.hasOwnProperty.call(res.data.dashboardHeatLocations, key)
              ) {
                const element = res.data.dashboardHeatLocations[key];
                let coordinates = element.location_LngLat.coordinates;
                let lat = coordinates[1];
                let lng = coordinates[0];
                this.heatMapPoints.push({ lat: lat, lng: lng });

                this.heatLocations = [...this.heatLocations, { lat, lng }];
              }
            }
          }
          // this.defaultLocation.lat = this.heatMapPoints[0].lat;
          // this.defaultLocation.lng = this.heatMapPoints[0].lng;
          // this.heatLocations = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.vue-map-container {
  height: 500px !important;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-xl-8">
        <Stat :stat="data" />
        <!-- <RevenueAnalytics /> -->
      </div>
      <div class="col-xl-4">
        <!-- <SalesAnalytics />
        <EarningReport /> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <h4>Driver in Locations</h4>
        <b-button
          @click="viewClusterMap"
          style="text-decoration: underline"
          variant="link"
          >Cluster View</b-button
        >

        <vue-google-heatmap
          :points="heatMapPoints"
          :lat="defaultLocation.lat"
          :lng="defaultLocation.lng"
          :options="mapOptions"
          :key="keyValue"
          ref="gmap"
          :initial-zoom="defaultLocation.zoom"
          :height="500"
        />
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-4">
        <Sources />
      </div>
      <div class="col-lg-4">
        <RecentActivity />
      </div>
      <div class="col-lg-4">
        <RevenueLocation />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <Chat />
      </div>
      <div class="col-lg-8">
        <Transaction />
      </div>
    </div> -->
  </Layout>
</template>
